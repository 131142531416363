<template>
    <div>

<section id="v_76">
    <!-- <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>Section 76</h2>
            </div>
        </div>
    </div> -->
    <div class="section-padding round-bg">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <a href="https://virza805.github.io/RRF-150-Section/" target="_blank" class="single-feature-item">
                        <div class="feature-ico">
                            <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                        </div>
                        <h2>Web statick section</h2>
                        <p>Web 140+ Section with some project</p>
                    </a>
                </div> <!--End Left side 1 item-->
                <div class="col-md-6">
                    <a href="https://vir-za.com/" target="_blank" class="single-feature-item left-icon">
                        <div class="feature-ico">
                            <i class="fa fa-wordpress" aria-hidden="true"></i>
                        </div>
                        <h2>My Live Site</h2>
                        <p> WordPress Custom Theme</p>
                    </a>
                </div> <!--End Left side 2 item-->
                <div class="col-md-6">
                    <a href="http://vir-za.com/m_react/" target="_blank" class="single-feature-item f-padding-left">
                        <div class="feature-ico">
                            <i class="fa fa-diamond" aria-hidden="true"></i>
                        </div>
                        <h2>React App</h2>
                        <p>Simple react project</p>
                    </a>
                </div> <!--End Left side 3 item-->
                <div class="col-md-6">
                    <a href="https://service24.vir-za.com/" target="_blank" class="single-feature-item f-padding-right left-icon">
                        <div class="feature-ico">
                            <i class="fa fa-themeisle" aria-hidden="true"></i>
                        </div>
                        <h2>Dive Theme</h2>
                        <p>Simple dive project</p>
                    </a>
                </div> <!--End Left side 4 item-->
                <div class="col-md-6">
                    <a href="https://www.linkedin.com/in/1mdalamin1/" target="_blank" class="single-feature-item">
                        <div class="feature-ico">
                            <i class="fa fa-linkedin" aria-hidden="true"></i>
                        </div>
                        <h2>Tanvir Hasan</h2>
                        <p>My linkedin profile</p>
                    </a>
                </div> <!--End Left side 5 item-->
                <div class="col-md-6">
                    <a href="https://seopage1.net/" target="_blank" class="single-feature-item left-icon">
                        <div class="feature-ico">
                            <i class="fa fa-briefcase" aria-hidden="true"></i>
                        </div>
                        <h2>Still working on office</h2>
                        <p>Seopage1 it solution</p>
                    </a>
                </div> <!--End Left side 6 item-->
            </div>
        </div>
    </div>
</section>
    
    <section id="v_66">

<div style="height: 120px;"></div>

  <div class="section-padding">
    <div class="fratures-arew">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="features-wrap">
              <div class="section_title">
                <img src="https://virza805.github.io/RRF-150-Section/img/S_66_logo.png" alt="">
                <h2> <a href="https://vir-za.com/af/acme/"> Show Task List </a></h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo.</p>
              </div>
              <div class="row">
                <div class="col-md-4 text-left">
                  <h3>Task Completed.</h3>
                  <ul>
                      <li>
                                1. vue cli install.
                            </li>
                            <li>
                                2. vue new project installtion.
                            </li>
                            <li>
                                3. vue router.
                            </li>
                            <li>
                                4. vuex store.
                            </li>
                            <li>
                                5. vue role base authentication.
                            </li>
                            <li>
                                6. laravel auth with passport.
                            </li>
                            <li>
                                7. laravel api routing.
                            </li>
                            <li>
                                8. laravel DB Seeding.
                            </li>
                            <li>
                                9. Laravel email.
                            </li>
                            <li>
                                10. multiple template connection.
                            </li>
                            <li>
                                11. html to vue conversion.
                            </li>
                            <li>
                                12. login registration.
                            </li>
                  </ul>
                </div>
                <div class="col-md-4  text-left">
                  <h3>. </h3>
                  <ul>
                      <li>
                                13. Book management.
                            </li>
                            <li>
                                14. Book entry management.
                            </li>
                            <li>
                                15. vue select2.
                            </li>
                            <li>
                                16. vue image uploading.
                            </li>
                            <li>
                                17. vue pagination.
                            </li>

                            <li>
                                18. vue multiple insertion.
                            </li>
                            <li>
                                19. vue multiple delete.
                            </li>
                            <li>
                                20. request progressbar.
                            </li>
                            <li>
                                21. axios setup.
                            </li>
                            <li>
                                22. Jquery setup.
                            </li>
                            <li>
                                23. cPanel hosting.
                            </li>
                  </ul>
                </div>
              </div>
              <img src="https://virza805.github.io/RRF-150-Section/img/s_66.png" class="hard-abseluat" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        <!-- About Area Start -->
        
<section>
    <div class="container-flud">
        <img style="width:100%" src="/assets/images/tanvir.png">
    </div>
</section>
        
        
        <!-- End of Event Area -->
<section id="v_37">
   <div class="sectino-paddingA">
     <div class="container">
       <div class="row">
         <div class="col-md-2">
           <h2 class="title-section">Our team</h2>
         </div>
         <div class="col-md-10">
           <div class="row">
             <div class="col-md-4">
               <div class="single-team-memberA">
                 <div class="team-bg1 bgC"></div>
                 <div class="borded-content">
                   <div class="team_title"><h3>Tanvir Hasan</h3></div>
                   <p>Web Developer</p>
                 </div>
               </div>
             </div>
             <div class="col-md-4">
               <div class="single-team-memberA">
                 <div class="team-bg1 bgA"></div>
                 <div class="borded-content">
                   <div class="team_title"><h3>zavir Hasan</h3></div>
                   <p>Web Developer</p>
                 </div>
               </div>
             </div>
             <div class="col-md-4">
               <div class="single-team-memberA">
                 <div class="team-bg1 bgB"></div>
                 <div class="borded-content">
                   <div class="team_title"><h3>virza Hasan</h3></div>
                   <p>Web Developer</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>

<section>
    <div class="container">
        <img style="margin:0 auto" src="/assets/images/virzaOk.gif">
    </div>
</section>

    </div>
</template>

<script>
export default {
    created: function(){
        
    }
}
</script>

<style scoped>
    .custom_ul{
        margin-bottom: 30px;
    }
    .custom_ul li{
        font-size: 18px;
        line-height: 36px;
    }
    ul.custom_ul {
    display: flex;
    flex-wrap: wrap;
}

ul.custom_ul li {
    width: 50%;
    text-align: left;
}
    .single-funfact h4 {
    color: #fff !important;
}
.about-img-size img{
    width: 100%;
}
/* FFR 150 Section Video_37 # 15/9/2019 9:55pm Start  */
#v_37{
    font-family: 'Roboto', sans-serif;
    padding: 80px 0;
}
.team-bg1 {
    background-image: url(https://virza805.github.io/RRF-150-Section/img/profil_pick.jpg);
    height: 270px;
    background-size: cover;
    background-position: center top;
    margin-bottom: 40px;
}
.bgA{background-image: url(https://virza805.github.io/RRF-150-Section/img/profil_ca.jpg)}
.bgB{background-image: url(https://virza805.github.io/RRF-150-Section/img/p-cam.jpg)}

.title-section{
    position: relative;
    text-transform:uppercase;
    text-align: 32px;
    font-weight: 900;
    transform:rotate(270deg);
    margin-top: 50px;
}
.title-section::before{
    position: absolute;
    top: -25px;
    right:0;
    height: 1px;
    width:70%;
    content: "";
    background: #0e0e0e;
}
#v_37 .borded-content{
    border: 1px solid #0e0e0e;
    text-align: center;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 2px;
    padding-bottom: 20px;
}
#v_37 .borded-content h3{
    font-size: 20px;
    background: #fff;
    display:inline-block;
    padding: 0 10px;
    margin-bottom: 5px;
}
#v_37 .team_title{
    margin-top:-11px;
}
#v_37 .col-md-4:nth-child(2) .single-team-memberA{
    margin-top: 100px;
}
/* FFR 150 Section Video_37 # 15/9/2019 11:03pm End  */

/* RRF 150 Section Video_76# 23/1/2020 9:55am on */
.round-bg::after{
    background-image: url(https://virza805.github.io/RRF-150-Section/img/Rose1.jpg);
}

.round-bg{
    position: relative;
    z-index: 1;
}
.round-bg::after{
    position: absolute;
    left: 51%;
    top:7%;
    height: 400px;
    width:400px;
    content:"";
    z-index: -1;
    border-radius: 50%;
    margin-left:-225px;
    margin-right: -225px;
    background-position: center;
    background-size: cover;
    background-color: #333;
}
.single-feature-item{
    display: block;
    position: relative;
    z-index: 1;
}
.single-feature-item:hover .feature-ico{
    box-shadow: 0 0 4px #333;
}
.feature-ico{
    position: absolute;
    right:0;
    top:0;
    background-color: #fff;
    height: 150px;
    width:150px;
    text-align: center;
    border-radius:50%;
    font-size: 50px;
    padding:10px;
}
.feature-ico i.fa{
    border: 1px solid #ddd;
    width:100%;
    height:100%;
    display:block;
    border-radius:50%;
    line-height: 120px;
}
.single-feature-item h2{
    font-weight: 400;
    font-size: 25px;
}
.single-feature-item{
    position: relative;
    z-index:1;
    text-align: right;
    padding-right: 170px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 90px;
    margin-right: 30px;
}
.left-icon .feature-ico{
    left:0;
    right:auto;
}
.single-feature-item.left-icon{
    margin-left: 0;
    padding-left: 170px;
    padding-right:90px;
    text-align: left;
}
.single-feature-item.f-padding-left{
    margin-left: 0;
    margin-right:120px;
}
.single-feature-item.f-padding-right{
    margin-right: 0;
    margin-left:100px;
}
/* RRF 150 Section Video_76 # 23/1/2020 10:42am End */

#v_66{
    font-size:15px;
    line-height: 1.8;
}
.section_title{
    text-align: center;
    margin-bottom: 150px;
}
.features-wrap{
    position:relative;
    z-index: 1;
    padding-bottom:170px;
}
.hard-abseluat{
    position: absolute;
    right: -100px;
    bottom:0;
}
a.single-feature-item {
    color: #2a3142;
}
</style>