<template>
  <!-- About Area Start -->
<div class="about-area section bg-white pt-120 pb-90">
    <div class="container">
        <div class="row">
            <!-- About Image -->
            <div class="about-image col-md-5 col-xs-12 float-right mb-30"><img src="https://virza805.github.io/RRF-150-Section/img/screenshot.jpg" alt=""></div>
            <!-- About Content -->
            <div class="about-content col-md-7 col-xs-12 mb-30">
                <h3>Protfolio psh</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text amr songr balga ami toami valo lasi ciri din akr dali</p>
                <a class="btn" href="about.html">Learn Now</a>
            </div>
        </div>
        <div class="row">
          <img src="https://virza805.github.io/RRF-150-Section/img/screenshot.jpg" alt="">
        </div>
    </div>
</div>
<!-- End of About Area -->
</template>

<script>
export default {

}
</script>

<style scoped>
img {
    vertical-align: middle;
    border-style: none;
    margin: 0 auto;
    display: block;
    width: 100%;
}
</style>